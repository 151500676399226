// export const URL = {
//     LOGIN: "/login",
//     QRLOGIN: "/login/:id",
//     PRODUCT_LIST: "/product",
//     ADD_TO_CART: "/addToCart",
//     GET_CART_DATA: "/getCartData",
//     GET_FRENCHISE_NAME:"/frenchiseList",
//     FRENCHISE_LOGIN: "/frenchiseLogin",
//     SEND_QR_IN_MAIL: "/sendMailForQR",
//     SEND_QR_IN_SMS: "/sendSMSForQR",
// }
export const URL = {
    LOGIN: "/user/login/",
    QRLOGIN: "/login/:id",
    PRODUCT_LIST: "/user/product/",
    ADD_TO_CART: "/user/add_to_cart/",
    GET_CART_DATA: "/user/get_cart_data/",
    GET_FRENCHISE_NAME: "/user/franchise/",
    FRENCHISE_LOGIN: "/user/franchise/login",
    SEND_QR_IN_MAIL: "/sendMailForQR",
    SEND_QR_IN_SMS: "/sendSMSForQR",
}