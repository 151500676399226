import React, { useEffect, useState } from "react";
import "./Login.css"
import { Autocomplete, Box, Button, Grid, Icon, Input, InputAdornment, Modal, Pagination, TableSortLabel, TextareaAutosize, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Logo from '../../../images/logo.png'
import LoaderLogo from '../../../images/loader-logo.png'
import LoaderRound from '../../../images/loader-round.svg'
import UserIcon from '../../../images/user-icon.svg'
import LocationIcon from '../../../images/location-icon.svg'
import MailIcon from '../../../images/mail-icon.svg'
import { BorderRight } from "@mui/icons-material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  Link, useLocation, useNavigate,
  useParams
} from "react-router-dom";
import { URL } from "../../Utils/constant";
import Loader from "../../components/Loader/Loader";
import axios from "../../Utils/axios-config";
const Login = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [emailMobile, setEmailMobile] = useState("")
  const [firstName, setFirstName] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [emailError, setEmailError] = useState("");
  // Helper function to set a cookie by name
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };
  const { id } = useParams();

  // Helper function to get a cookie by name
  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  // for removing cookie
  function removeCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  useEffect(() => {
    // const empId = JSON.parse(localStorage.getItem("paymore_emp_id"))
    // if (!empId?.token) {
    //   navigate('/')
    // }

    if (id) {
      const cookieInfo = getCookie("paymore_req_id");
      if (!cookieInfo) {
        setCookie("paymore_req_id", id, 7); // Set cookie with 7 days expiry
      }
    }

    const userId = JSON.parse(localStorage.getItem("paymore_user_id"))?.userid
    if (userId) {
      navigate('/products')
    }
    setLoader(false)
  }, [])

  const handleProcessOrSkip = async (e) => {
    e.preventDefault()
    setLoader(true)
    const data = {
      email_mobile: emailMobile,
      first_name: firstName,
      zipcode: zipcode,
      franchiseName: JSON.parse(localStorage.getItem("paymore_emp_id"))?.franchiseData?.franchiseName || "",
      qrInfo: getCookie("paymore_req_id") || ""
    }
    try {
      const response = await axios.post(URL?.LOGIN, data)
      const checkVerify = response.data;
      console.log("checkVerify", checkVerify)
      const maindata = {
        userid: checkVerify?.data?.data?._id,
        email_mobile: emailMobile,
        user_name: firstName,
        zipcode: zipcode,
        cart_ids: [],
        qrInfo: getCookie("paymore_req_id") || ""
      }
      localStorage.setItem("paymore_user_id", JSON.stringify(maindata))
      removeCookie("paymore_req_id");
      navigate('/products')
    } catch (err) {
      console.log('=====>', err)
      if (err?.response?.status === 400) {
        alert(err.response?.data?.error)
      }
      setLoader(false)
      localStorage.setItem("paymore_emp_id", "{}")
      localStorage.setItem("paymore_user_id", "{}")
      // navigate('/')
      navigate('/login')
    }

  }
  return (
    <>
      <Loader loader={loader} />
      <Box className="login-wrap" sx={{
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
      }}>
        <Box sx={{
          position: 'absolute',
          bottom: '30px',
          right: '30px',
          '& a': {
            background: 'transparent',
            border: 'none',
            padding: '0',
            borderBottom: '1px solid #ffffff',
            color: '#ffffff',
            cursor: 'pointer',
            opacity: '0.5',
          }
        }}>
          <a onClick={(e) => handleProcessOrSkip(e)}>skip</a>
        </Box>
        <Box sx={{
          border: '1px solid rgba(255, 255, 255, 0.7)',
          borderRadius: '25px',
          background: 'linear-gradient(90deg, rgba(255,255,255,0.5046612394957983) 0%, rgba(255,255,255,0.20214023109243695) 100%)',
          width: '500px',
          maxWidth: '100%',
          padding: '60px',
        }}>
          <Typography variant="h2" sx={{
            fontSize: '60px',
            fontFamily: '"Nunito Sans", sans-serif',
            color: "#fff",
            marginBottom: '10px',
            '& img': {
              marginTop: '0px',
              display: 'block',
              width: "100%"
            }
          }}>
            Welcome to
            <img src={Logo} />
          </Typography>
          <Box sx={{
            maxWidth: '260px',
            margin: '0px auto 0',
            '& .input-box': {
              position: 'relative',
              '& img': {
                position: 'absolute',
                left: '0',
                top: '9px',
              },
              '& input': {
                border: 'none',
                borderBottom: '1px solid #fff',
                background: 'transparent',
                color: '#ffffff',
                fontSize: '16px',
                width: '100%',
                padding: '10px 0px 10px 27px',
                '&::placeholder': {
                  color: '#ffffff',
                }
              }
            },
            '& .input-box + .input-box': {
              marginTop: '20px',
            }
          }}>
            <form onSubmit={handleProcessOrSkip}>
              <Box className="input-box">
                <img src={MailIcon} />
                <input required placeholder="E-mail / Mobile" onChange={(e) => setEmailMobile(e.target.value)} type="text" />
              </Box>
              {/* <p style={{ color: "red" }}>{emailError}</p> */}
              <Box className="input-box">
                <img src={UserIcon} />
                <input required placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} type="text" />
              </Box>
              <Box className="input-box">
                <img src={LocationIcon} />
                <input placeholder="Zip Code" onChange={(e) => setZipcode(e.target.value)} type="text" />
              </Box>
              <Box sx={{
                marginTop: '30px',
                '& button': {
                  background: '#64BC46',
                  borderRadius: '100px',
                  border: 'none',
                  width: '100%',
                  fontSize: '16px',
                  textTransform: 'uppercase',
                  fontWeight: '500',
                  color: '#fff',
                  padding: '15px 20px'
                }
              }}>
                <button type="submit">
                  Proceed
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>

  )
}

export default Login