import React, { useEffect, useState } from "react";
import "./Products.css"
import { Autocomplete, Box, Button, Grid, Icon, Input, InputAdornment, Modal, Pagination, TableSortLabel, TextareaAutosize, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import InfiniteScroll from "react-infinite-scroll-component";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NavBar from '../../components/NavBar/NavBar'
import productImg from '../../../images/product-img.jpg'
import plusIcon from '../../../images/plus-icon.svg'
import checkmark from '../../../images/checkmark.svg'
import defaultImage from '../../../images/image.svg'
import {
  useLocation, useNavigate
} from "react-router-dom";
import { URL } from "../../Utils/constant";
import Loader from "../../components/Loader/Loader";
import axios from "../../Utils/axios-config";

const Products = () => {
  const [loader, setLoader] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [seachString, setSeachString] = useState("")
  const [otherFilters, setOtherFilters] = useState("")
  const [offset, setOffset] = useState(0)
  const [productData, setProductData] = useState([])
  const [pruductIds, setProductIds] = useState([])
  const [productLocalData, setProductLocalData] = useState([])
  const [getShowImageLarge, setShowImageLarge] = useState(false);
  const [scaleForLargeImage, setScaleForLargeImage] = useState("4")

  const [cartData, setCartData] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    const empId = JSON.parse(localStorage.getItem("paymore_emp_id"))
    // if (!empId?.token) {
    //   navigate('/')
    // }
    const userId = JSON.parse(localStorage.getItem("paymore_user_id"))
    if (!userId?.userid) {
      navigate('/login')
    } else {
      setProductIds(userId?.cart_ids?.length ? userId?.cart_ids : [])
      setProductLocalData(userId?.cart_data?.length ? userId?.cart_data : [])
      getCartData(userId)
      getProduct(0)
    }
  }, [])
  const onSearchKeyPress = async (event) => {
    // If Enter is pressed while typing in search field
    if (event.keyCode == 13) {
      event.preventDefault();
      setOffset(0)
      setLoader(true)
      setProductData([])
      getProduct(0, [])

      window.scrollTo(0, 0)
    }
  };

  const getProduct = async (os, pdata) => {
    let offsetdata = os
    // const data = {
    //   search: seachString,
    //   otherFilters: otherFilters,
    //   offset: offsetdata * 10,
    //   limit: 10
    // }
    const data = {
      search: seachString,
      filter_by: otherFilters,
      offset: offsetdata * 10,
      limit: 10,
      isCheckout: false
    }
    try {
      const response = await axios.post(URL.PRODUCT_LIST, data)
      const productResult = response?.data;
      let productDetails = pdata ?? productData
      const products = [...productDetails, ...productResult?.data?.data]

      setProductData(products)
      setOffset(offsetdata + 1)
      setLoader(false)
      if (!productResult?.data?.data?.length) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }

    } catch (err) {
      console.log('==err===>', err)
      setLoader(false)
      localStorage.setItem("paymore_emp_id", "{}")
      localStorage.setItem("paymore_user_id", "{}")
      // navigate('/')
      navigate('/login')
    }
  }
  const getCartData = async (data) => {
    try {
      // const checkCart = await axios.post(URL?.GET_CART_DATA, { userid: data?.userid })
      const checkCart = data
      // JSON.parse(localStorage.getItem("paymore_user_id"))
      if (JSON.parse(JSON.stringify(checkCart?.cart_data))) {
        setCartData(JSON.parse(JSON.stringify(checkCart?.cart_data)))
      }
    } catch (err) {
      console.log('===ADD_TO_CART==>', err)
    }
  }

  const onAddToCart = async (data, pids, pdata) => {
    console.log("data", data)
    const userId = JSON.parse(localStorage.getItem("paymore_user_id"))
    const productids = pids ?? pruductIds
    const productdatas = pdata ?? productLocalData
    if (!userId?.userid) {
      navigate('/login')
      localStorage.setItem("paymore_user_id", "{}")
    }
    let product_ids = pids ? pids : pruductIds
    let product_data = pdata ? pdata : productLocalData

    if (data?._id && pruductIds.indexOf(data?._id) === -1) {
      product_ids.push(data?._id)
      product_data.push(data)
    }
    const bodydata = {
      cart_ids: product_ids,
      userid: userId?.userid,
      cart_data: product_data
    }

    userId.cart_ids = product_ids
    userId.cart_data = product_data
    setProductIds(product_ids)
    setProductLocalData(product_data)
    getCartData(userId)

    localStorage.setItem("paymore_user_id", JSON.stringify(userId))
    try {
      const checkCart = await axios.post(URL?.ADD_TO_CART, bodydata)
      if (!checkCart?.data?.result?.acknowledged) {
        setProductIds(productids)
        setProductLocalData(productdatas)
        userId.cart_ids = productids
        userId.cart_data = productdatas
        localStorage.setItem("paymore_user_id", JSON.stringify(userId))
        getCartData(userId)
      }
    } catch (err) {
      console.log('===ADD_TO_CART==>', err)
      setProductIds(productids)
      setProductLocalData(productdatas)
      userId.cart_ids = productids
      userId.cart_data = productdatas
      localStorage.setItem("paymore_user_id", JSON.stringify(userId))
      getCartData(userId)
    }
  }
  const handleRemoveFromCart = (data) => {
    const afterRemove = pruductIds?.filter((itm) => itm != data?._id)
    const afterRemoveData = productLocalData?.filter((itm) => itm?._id != data?._id)
    setProductIds(afterRemove)
    setProductLocalData(afterRemoveData)
    onAddToCart("", afterRemove, afterRemoveData)
  }
  function toggleTransform(i, img) {
    setShowImageLarge(getShowImageLarge?.id === i ? false : { id: i, img: img })
    if (!i) {
      setScaleForLargeImage("4")
    }
  }
  const onLargeImageClick = () => {
    if (scaleForLargeImage === "4") {
      setScaleForLargeImage("5")
    } else {
      toggleTransform(false)
      setScaleForLargeImage("4")
    }
  }
  return (
    <>
      <Loader loader={loader} />
      <NavBar cartlen={cartData?.length} cartData={cartData} setSeachString={setSeachString} onSearchKeyPress={onSearchKeyPress} handleRemoveFromCart={handleRemoveFromCart} />
      <Box sx={{
        padding: '0 20px',
      }}>
        <TableContainer>
          <InfiniteScroll
            style={{
              overflow: "hidden",
            }}
            dataLength={productData?.length}
            next={() => getProduct(offset)}
            hasMore={hasMore}
            loader={
              offset ? <h4 style={{ alignItems: "center", display: "flex", justifyContent: "center", color: "#64bc46" }}>Loading...</h4> : ""
            }
            endMessage={
              <h4 style={{ alignItems: "center", display: "flex", justifyContent: "center", color: "#64bc46" }}>...</h4>
            }

          >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead onClick={(e) => toggleTransform(false)}>
                <TableRow>
                  <TableCell sx={{
                    width: '16%',
                  }}>
                    <Typography variant="span" sx={{
                      margin: 0,
                      background: '#F9F9F9',
                      borderRadius: '6px',
                      color: '#A1A5B7',
                      padding: '5px 20px',
                      display: 'inline-block',
                    }}>
                      Image
                    </Typography>
                  </TableCell>
                  <TableCell sx={{
                    width: '55%',
                  }}>
                    <Typography variant="span" sx={{
                      margin: 0,
                      background: '#F9F9F9',
                      borderRadius: '6px',
                      color: '#A1A5B7',
                      padding: '5px 20px',
                      display: 'inline-block',
                    }}>
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell sx={{
                    width: '20%',
                  }}>
                    <Typography variant="span" sx={{
                      margin: 0,
                      background: '#F9F9F9',
                      borderRadius: '6px',
                      color: '#A1A5B7',
                      padding: '5px 17px',
                      display: 'inline-block',
                    }}>
                      Price
                    </Typography>
                  </TableCell>
                  <TableCell sx={{
                    width: '14%',
                  }}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>

                {productData?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell width={150}>
                      <Box sx={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '4px',
                        lineHeight: '0',
                        // overflow: 'hidden',
                        '& img': {
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                          transition: 'all 0.3s ease 0s',
                        },
                        '& img:hover': {
                          transform: 'scale(1.2)',
                        }
                      }}>
                        <img style={{ border: '1px solid black', cursor: "pointer" }} src={row?.p_img_thumbnail ? row?.p_img_thumbnail : defaultImage} onClick={(e) => toggleTransform(row?._id, row?.p_img_large ? row?.p_img_large : defaultImage)} alt="" />
                        {getShowImageLarge?.id ? (<>
                          <img
                            className="transformed"
                            style={{
                              transform: `scale(${scaleForLargeImage ? scaleForLargeImage : "4"})`,
                              // transition: 'transform 0.3s',
                              display: 'block',
                              position: 'fixed',
                              margin: '0 auto',
                              borderRadius: '10px',
                              border: '1px solid black',
                              top: '50%',
                              left: '50%',
                              transformOrigin: 'center center',
                              zIndex: '9998',
                              cursor: 'pointer'
                            }}
                            id={`productImage`}
                            onClick={(e) => onLargeImageClick()}
                            // onClick={(e) => toggleTransform(false)}
                            src={getShowImageLarge?.img ? getShowImageLarge?.img : defaultImage}
                            alt="product img"
                          />
                        </>) : ""}
                      </Box>
                    </TableCell>
                    <TableCell onClick={(e) => toggleTransform(false)}>
                      <Typography sx={{
                        fontWeight: '500'
                      }}>
                        {row?.name}
                      </Typography>
                      {/* <Typography>
                      {row?.model}
                    </Typography> */}
                    </TableCell>
                    <TableCell onClick={(e) => toggleTransform(false)}>
                      <Typography sx={{
                        fontWeight: '500',
                        display: "flex", justifyContent: "center", color: "#64BC46", fontWeight: "bold"
                      }}>
                        {/* ${row?.minPrice} - ${row?.maxPrice} */}
                        ${row?.price}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{
                        '& button': {
                          width: '30px',
                          height: '30px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: 'none',
                          background: '#DDDDDD',
                          borderRadius: '100%',
                        }
                      }}>
                        {(pruductIds?.indexOf(row?._id) === -1) ?
                          <button style={{ cursor: "pointer" }} onClick={() => onAddToCart(row)}>
                            <img src={plusIcon} />
                          </button> :
                          <button onClick={() => handleRemoveFromCart(row)}>
                            <img style={{ width: "36px", cursor: "pointer" }} src={checkmark} />
                          </button>}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </InfiniteScroll>
        </TableContainer>
      </Box>
    </>

  )
}

export default Products