import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Products from './views/Products/Products';
import Login from './views/Login/Login';
import EmpLogin from './views/EmpLogin/EmpLogin';
import QR from './views/QRpublic/QR';

const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<EmpLogin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/:id" element={<Login />} />
            <Route path="/products" element={<Products />} />
            <Route path="/qr/:userid" element={<QR />} />

        </Routes>
    )
}

export default AllRoutes