import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import Logo from '../../../images/paymore-logo-dark.png'
import Logo from '../../../images/logo.png'
import SearchIcon from '../../../images/search-icon.svg'
import cartIcon from '../../../images/cart-icon.svg'
import filterIcon from '../../../images/filter-icon.svg'
import List from '@mui/material/List';
import productImg from '../../../images/product-img.jpg'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseFil from '../../../images/close-fil.svg'
import defaultImage from '../../../images/image.svg'
import {
  useNavigate
} from "react-router-dom";
import QRCodeModal from '../QRcode/QRmodal';
const NavBar = ({ cartlen, cartData, setSeachString, onSearchKeyPress, handleRemoveFromCart }) => {
  const [state, setState] = useState({ right: false });
  const [userName, setUserName] = useState("")
  const [openQRCodeModal, SetOpenQRCodeModal] = useState(false)
  const navigate = useNavigate()

  const [openLogout, setLogout] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("paymore_user_id"))
    if (!userId?.user_name) {
      setUserName("Guest")
    } else {
      setUserName(userId?.user_name)
    }
  }, [])
  const onCloseList = () => {
    // localStorage.setItem("paymore_user_id", "{}")
    // navigate("/")
    setLogout(true)

  }
  const onClickClose = () => {
    localStorage.setItem("paymore_user_id", "{}")
    // navigate("/")
    navigate("/login")
  }
  const minPrice = cartData.reduce((accumulator, currentValue) => {
    const minPrices = currentValue?.minPrice || currentValue.price || 0;
    return accumulator + minPrices;
  }, 0);
  const maxPrice = cartData.reduce((accumulator, currentValue) => {
    const minPrices = currentValue?.maxPrice || currentValue.price || 0;
    return accumulator + minPrices;
  }, 0);
  const list = (anchor) => (
    <Box
      sx={{}}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '2px solid #96969633',
        '& button': {
          background: 'transparent',
          padding: '0',
          border: 'none',
          cursor: 'pointer',
        }
      }}>
        <Typography sx={{
          fontSize: '20px',
          fontWeight: 'bold',
        }}>
          Your Cart
        </Typography>
        <button onClick={toggleDrawer(anchor, false)}>
          <img src={CloseFil} />
        </button>
      </Box>
      {cartData?.length ?
        <Box>
          {cartData?.map((item, i) => {
            return (
              <Box
                key={i}
                sx={{

                  display: 'flex',
                  padding: '20px 20px 0 20px',
                }}>
                <Box sx={{
                  width: '100px',
                  '& img': {
                    width: '100px',
                    height: '100px',
                    borderRadius: '4px',
                    border: '1px solid #969696',
                  }
                }}>
                  <img src={item?.p_img ?item?.p_img: defaultImage} alt="" />
                </Box>
                <Box sx={{
                  width: 'calc(100% - 250px)',
                  padding: '0 15px',
                }}>
                  <Typography sx={{
                    lineHeight: '22px',
                  }}>
                    {item?.name}
                  </Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'end',
                  width: '150px',
                  '& button': {
                    background: 'transparent',
                    border: 'none',
                    padding: '0',
                    borderBottom: '1px solid #3F425480',
                    color: '#3F425480',
                    cursor: 'pointer',
                  }
                }}>
                  <Typography sx={{
                    fontWeight: 'bold',
                  }}>
                    {!isNaN(item?.minPrice) && !isNaN(item?.maxPrice) ? ("$" + item?.minPrice + " - " + "$" + item?.maxPrice)
                      : "$" + item?.price}
                  </Typography>
                  <button onClick={() => handleRemoveFromCart(item, i)}>Remove</button>
                </Box>
              </Box>
            )
          })}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px',
            borderTop: '2px solid #96969633',
            marginTop: '20px',
          }}>
            <Typography>Total</Typography>
            <Typography sx={{
              fontWeight: 'bold',
            }}>
              ${minPrice} - ${maxPrice}
            </Typography>
          </Box>
        </Box> : ""}
    </Box>
  );
  const openLink = (link) => {
    var win = window.open(link, '_blank');
    win.focus();
  };
  const userid = JSON.parse(localStorage.getItem("paymore_user_id"))?.userid
  const onEmptyCart = () => {
    // localStorage.setItem("paymore_user_id", "{}")
    // navigate('/login')
    setLogout(true)
  }
  return (
    <>
      <Modal
        open={openQRCodeModal}
        onClose={() => SetOpenQRCodeModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "90px"
        }}
      >
        <Box >
          <QRCodeModal QRGeneratorSecretsId={userid} />
        </Box>
      </Modal>
      <Modal
        open={openLogout}
        onClose={() => setLogout(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "90px",
         
        }}
      >
        <Box sx={{background:"#fff",borderRadius:"10px", height:"135px",marginTop:"30%"}}>
          <Typography sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: "#64BC46",
            paddingTop: "18px",
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingBottom: "8px",
            display: "flex", justifyContent: "center", alignItems: "center",
          }} id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to logout?
          </Typography>
          <Box  sx={{ padding: "15px 10px 0px 10px", display: "flex", justifyContent: "center", alignItems: "center", }}>

            <Box sx={{
              marginTop: '0',
              marginRight: '10px',
              '& button': {
                background: '#64BC46',
                borderRadius: '10px',
                border: 'none',
                width: '100%',
                fontSize: '15px',
                fontWeight: '500',
                color: '#fff',
                padding: '10px 12px'
              }
            }}>
              <button title='Confirm' onClick={() => onClickClose()}>
                Confirm
              </button>
            </Box>
            <Box sx={{
              marginTop: '0',
              marginRight: '10px',
              '& button': {
                background: '#b7b7b791',
                borderRadius: '10px',
                border: 'none',
                width: '100%',
                fontSize: '15px',
                fontWeight: '500',
                color: '#000',
                padding: '10px 12px'
              }
            }}>
              <button title='Cancle' onClick={() => setLogout(false)}>
                Cancel
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box sx={{
        position: 'sticky',
        top: '0',
        background: '#fff',
        padding: '0 20px',
        zIndex: '98',
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '2px solid #96969633',
          padding: '20px 0',
        }}>
          <Box sx={{
            width: '22%',
            '& img': {
              width: '140px',
            }
          }}>
            <img src={Logo} />
          </Box>
          <Box sx={{
            width: '56%',
            padding: '0 20px',
            textAlign: 'center',
            '& p': {
              fontSize: '17px',
              background: '#64BC46',
              borderRadius: '100px',
              color: '#ffffff',
              padding: '7px 15px',
              display: 'inline-block',
            }
          }}>
            <Typography>Search for your devices here to see how much cash you’re sitting on</Typography>
          </Box>
          <Box sx={{
            width: '22%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& p': {
              color: '#969696',
            }
          }}>
            <Typography>Hi, {userName}</Typography>
            {['right'].map((anchor) => (
              <React.Fragment key={anchor}>
                <Box sx={{
                  marginLeft: '15px',
                  '& button': {
                    padding: '0 0 0 0',
                    background: 'transparent',
                    border: 'none',
                    position: 'relative',
                    cursor: 'pointer',
                    marginRight: '5px',
                    '& span': {
                      position: 'absolute',
                      width: '20px',
                      height: '20px',
                      background: '#64bc47',
                      top: '0',
                      zIndex: '1',
                      right: '-5px',
                      color: '#fff',
                      borderRadius: '100%',
                      lineHeight: '20px',
                    }
                  }
                }}>
                  <button onClick={toggleDrawer(anchor, true)}>
                    <img src={cartIcon} />
                    <span>{cartData?.length}</span>
                  </button>
                </Box>

                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  sx={{
                    '& .MuiPaper-root': {
                      width: '500px',
                      maxWidth: '100%',

                    }
                  }}
                >
                  {list(anchor)}
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "40px"
                  }}>
                    <span style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      color: "#64BC46"
                    }}>
                      PayMore Reston

                    </span>
                    <span style={{
                      fontSize: "11px",
                      color: "#8b8889"
                    }}>
                      (11.8 miles away)
                    </span>
                    <span style={{ fontSize: "12px", color: '#000' }}>
                      1675 Reston Parkway Suite J
                    </span>
                    <span style={{ fontSize: "12px", color: '#000' }}>
                      Reston, VA 2019
                    </span>
                    <div >
                      <div style={{
                        fontSize: 12,
                        color: '#000',
                        marginRight: 18,
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <a href={`tel:(703) 349-6300`} style={{
                          textDecoration: 'none', color: '#000', textDecoration: 'none',
                        }}>
                          <span style={{
                            fontSize: 14
                          }} ><PhoneForwardedIcon style={{ fontSize: 12, color: '#64BC46', marginRight: 7 }} /></span><span style={{ color: '#000' }}>
                            (703) 349-6300
                          </span>
                        </a>
                      </div>
                      <div style={{
                        cursor: 'pointer', fontSize: 12,
                        color: '#000',
                        marginRight: 18,
                        display: 'flex',
                        alignItems: 'center',
                      }} onClick={() => openLink("https://restonva.paymore.com/")}>
                        <span><AddToHomeScreenIcon style={{ fontSize: 12, color: '#64BC46', marginRight: 7 }} /></span><span>Shop This Store!</span>
                      </div>
                      <div style={{
                        cursor: 'pointer', cursor: 'pointer', fontSize: 12,
                        color: '#000',
                        marginRight: 18,
                        display: 'flex',
                        alignItems: 'center',
                      }} onClick={() => openLink("https://www.google.com/maps/dir//Pizza+Boli's,+1675+Reston+Pkwy+Suite+M,+Reston,+VA+20194,+United+States/@38.968044,-77.4378999,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89b637e758284067:0xeae523dfffe15136!2m2!1d-77.3554848!2d38.9680625?entry=ttu")}>
                        <span>
                          <LocationOnIcon style={{ fontSize: 12, color: '#64BC46', marginRight: 7, marginTop: "2px" }} />
                        </span><span>Direction Link</span>
                      </div>
                    </div>
                    {cartData?.length ? <Box sx={{ display: "flex", }}>
                      <Box sx={{
                        marginTop: '30px',
                        marginRight: '10px',
                        '& button': {
                          background: '#64BC46',
                          borderRadius: '10px',
                          border: 'none',
                          width: '100%',
                          fontSize: '13px',
                          fontWeight: '500',
                          color: '#fff',
                          padding: '11px 20px'
                        }
                      }}>
                        <button onClick={() => SetOpenQRCodeModal(true)}>
                          Get Paid Now
                        </button>
                      </Box>
                      <Box sx={{
                        marginTop: '30px',
                        '& button': {
                          background: '#b7b7b791',
                          borderRadius: '10px',
                          border: 'none',
                          width: '100%',
                          fontSize: '13px',
                          fontWeight: '500',
                          color: '#000',
                          padding: '11px 20px'
                        }
                      }}>
                        <button onClick={() => onEmptyCart()}>
                          Empty Cart/Leave
                        </button>
                      </Box>
                    </Box> : ""}
                  </Box>
                </SwipeableDrawer>
              </React.Fragment>
            ))}
            <Box sx={{
              marginLeft: '15px',
            }}>
              <span onClick={onCloseList}>
                <img src={CloseFil} />
              </span>
            </Box>

          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '15px 0',
        }}>
          <Box sx={{
            width: '100%',
            marginRight: '30px',
            position: 'relative',
            '& input': {
              width: '100%',
              background: '#DDDDDD',
              border: 'none',
              fontSize: '16px',
              padding: '14px 50px 14px 50px',
              borderRadius: '100px',
            },
            '& button': {
              position: 'absolute',
              left: '20px',
              background: 'transparent',
              padding: '0',
              border: 'none',
              top: '13px',
            }
          }}>
            <input onChange={(e) => setSeachString(e.target.value)} onKeyDown={(e) => onSearchKeyPress(e)} placeholder='Search' />
            <button>
              <img src={SearchIcon} />
            </button>
          </Box>
          {/* <Box sx={{
            '& button': {
              background: '#DDDDDD',
              border: 'none',
              width: '42px',
              height: '42px',
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
          }}>
            <button>
              <img src={filterIcon} />
            </button>
          </Box> */}
        </Box >
      </Box >
    </>
  )
}

export default NavBar